import Hangman from './hangman.png';
import PortfolioV1 from './PortfolioV1.png';
import SimpleCalculator from './SimpleCalculator.png';
import AvoidDuplicateCalls from './AvoidDuplicateCalls.png';
import RxJsPairwise from './RxJsPairwise.png';
import Budget from './Budget.png';
import Inventory from './Inventory.png';
import PartsInProduct from './PartsInProduct.png';
import WGUApp from './WGUApp.png';
import BudgetApi from './BudgetApi.png'
import DefaultImage from './computer.png';

const imageMap = new Map<string, any> ([
    [ "angular-hangman-dakodaw", Hangman],
    [ "Appointment-Scheduler", DefaultImage ],
    [ "avoid-using-function-calls-template", AvoidDuplicateCalls ],
    [ "Budget", Budget ],
    [ "BudgetApi", BudgetApi ],
    [ "Inventory", Inventory ],
    [ "PartsInProducts", PartsInProduct ],
    [ "PortfolioAngular", PortfolioV1 ],
    [ "react-calculator", SimpleCalculator ],
    [ "rxjs-operators-template-pairwise", RxJsPairwise ],
    [ "WGUMobileProject", WGUApp ],
    [ "", DefaultImage ]
]);

export const GetMainRepoImage = (repoName: string) => {
    return imageMap.get(repoName);
}