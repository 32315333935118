import { useEffect, useState } from "react";
import './Home.scss'
import { UsableProjectDetails } from "../Shared/Dtos/UsableProjectDetails";
import { RequestProjectDetails } from "../Shared/Requests/RequestProjectDetails";
import { ProjectSection } from "../ProjectSection/ProjectSection";
import { GetStaticTechnologies } from "../Shared/GetStaticTechnologies";
import CustomCursor from "../CustomCursor/CustomCursor";
import { GetSessionObject, SetSessionObject } from "../Shared/StorageService/StorageService";
import { GetProfileData } from "../Shared/Requests/GetProfileData";
import { GetMainTitles } from "../TitleAnimations/AnimatedTitleMap";

export const Home = () => {
    const repoKey: string = 'repoData';
    const profileKey: string = 'profileData';

    const [profileData, setProfileData] = useState(null);
    const [reposData, setReposData] = useState<UsableProjectDetails[]>([]);
    const [cardHover, setCardHover] = useState(false);

    const GetRepos = async () => {
        const reposData = await fetch(`https://api.github.com/users/dakodaw/repos`)
            .then(response => response.json())
            .then(async json => {
                return Promise.all((json as unknown as RequestProjectDetails[])?.map(async x => { 
                    let languages: string[] = [];
                    if (!!x.languages_url) {
                        // const languagesResult = await fetch(x.languages_url);
                        // const languagesJson = await languagesResult.json();
                        // languages = GetStaticTechnologies(x.name, Object.keys(languagesJson));
                        languages = GetStaticTechnologies(x.name, []);
                    }
                    return {
                        description: x.description,
                        name: x.name,
                        liveDemoUrl: x.homepage,
                        mainLanguage: x.language,
                        languages: languages,
                        codeUrl: x.html_url
                    }
                }));
            });
        
        SetSessionObject(repoKey, reposData);

        setReposData(reposData);
    }
  
    useEffect(() => {
        const dataFromSession = GetSessionObject(profileKey);

        !!dataFromSession 
            ? setProfileData(dataFromSession as any)
            : GetProfileData(setProfileData);
    }, []);

    useEffect(() => {
        const blah = GetSessionObject(repoKey);

        !!blah
            ? setReposData(blah as any)
            : GetRepos();
        
    }, []);
  
    console.log('The converted stuff?', reposData)
    const titleImage = GetMainTitles('projects');

    return (
        <>
            <section className='projects-section'>
                {/* <h1 className='section-header'>Projects</h1> */}
                <img className='home-page-title-image' src={titleImage} alt='main projects title'  />
                <div className='projects'>
                    {reposData?.map((x, index) => {
                            return (
                                <ProjectSection 
                                    key={x.name} 
                                    projectInfo={x}
                                    setCardHover={setCardHover}
                                    id={index}
                                    />
                            );
                        })
                    }

                    <CustomCursor
                        elementHover={cardHover}
                        hoverText="See More" />
                </div>
            </section>
        </>
    );
}