const staticTechnologiesMap = new Map<string, string[]> ([
    [ "angular-hangman-dakodaw", [ "angular", "CSS", "HTML", "TypeScript"] ],
    [ "Appointment-Scheduler", [".net framework", "c#"] ],
    [ "avoid-using-function-calls-template", ["angular", "TypeScript", "HTML", "CSS"] ],
    [ "Budget", [".net framework", "JavaScript", "c#", "HTML"] ], // Should I add jQuery logo?
    [ "BudgetApi", [".net core", "c#"] ],
    [ "Inventory", ["JavaScript", "c#", "CSS", "HTML", ".net framework"] ], // Should I add jQuery logo?
    [ "PartsInProducts", ["c#"] ],
    [ "PortfolioAngular", ["TypeScript", "HTML", "SCSS", "JavaScript", "PowerShell", "angular"] ],
    [ "react-calculator", ["TypeScript", "HTML", "CSS","react"] ],
    [ "rxjs-operators-template-pairwise", ["TypeScript", "HTML", "angular"] ],
    [ "WGUMobileProject", ["c#", "xamarin"] ],
    [ "", [] ]
]);

const technologiesToFilterOut: string[] = [
    "asp",
    "asp.net"
]

export const GetStaticTechnologies = (repoName: string, technologies: string[]) => {
    const staticTechnology = staticTechnologiesMap.get(repoName);

    if (!!staticTechnology) {
        technologies = technologies.concat(...staticTechnology);
    }
    
    technologiesToFilterOut.forEach(t => {
        technologies = technologies.filter(x => t !== x.toLowerCase());
    });

    return technologies;
}
