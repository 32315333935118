import './TechIcon.scss'
export interface TechIconInput {
    techName: string
}

const iconNameMap = new Map<string, string>([
    ["angular", "angular-icon"],
    ["c#", "c-sharp-icon"],
    ["typescript", "typescript-icon"],
    ["html", "html-icon"],
    ["css", "css-icon"],
    ["javascript", "javascript-icon"],
    ["asp.net", ""],
    ["asp", ""],
    [".net framework", "dot-net-framework-icon"],
    [".net core", "dot-net-core-icon"],
    ["scss", "sass-icon"],
    ["react", "react-icon"],
    ["powershell", "powershell-icon"],
    ["xamarin", "xamarin-icon"],
    ["", ""]
])

export const TechIcon = (input: TechIconInput) => {
    let techIcon = iconNameMap.get(input.techName?.toLowerCase()) ?? null;

    return (
        <>
            {
                !!techIcon
                    ? <img className="tech-icon" src={require(`../../Icons/${techIcon}.png`)} alt={`${input.techName} icon`} title={input.techName}/>
                    : <span>{input.techName}</span>
            }
        </>
    );
}