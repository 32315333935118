import './ProjectPage.scss';
import { Link, useParams } from "react-router-dom";
import { GetFriendlyRepoName } from "../Shared/GetFriendlyRepoName";
import { useEffect, useState } from 'react';
import { RequestProjectDetails } from '../Shared/Requests/RequestProjectDetails';
import { GetStaticTechnologies } from '../Shared/GetStaticTechnologies';
import { UsableProjectDetails } from '../Shared/Dtos/UsableProjectDetails';
import { GetMainRepoImage } from '../Images/ImageMap';
import CustomCursor from '../CustomCursor/CustomCursor';
import { GetMainRepoTitle } from '../TitleAnimations/AnimatedTitleMap';
import { GetProjectBackground, GetProjectInstructions } from './ProjectDetails';

export const ProjectPage = () => {
    const { id } = useParams();
    const friendlyRepoName = GetFriendlyRepoName(id ?? "");
    const mainPageImage = GetMainRepoImage(id ?? "");
    const titleImage = GetMainRepoTitle(id ?? "");
    const pageBackgroundDescription = GetProjectBackground(id ?? "");
    
    const [repoData, setRepoData] = useState<UsableProjectDetails>();

    const [cardHover, setCardHover] = useState(false);

    const GetRepo = async () => {
        const repoData = await fetch(`https://api.github.com/repos/dakodaw/${id}`)
            .then(response => response.json())
            .then(async (json: RequestProjectDetails) => {
                let languages: string[] = [];
                if (!!json.languages_url) {
                    const languagesResult = await fetch(json.languages_url);
                    const languagesJson = await languagesResult.json();
                    languages = GetStaticTechnologies(json.name, Object.keys(languagesJson));
                }
                return {
                    description: json.description,
                    name: json.name,
                    liveDemoUrl: json.homepage,
                    mainLanguage: json.language,
                    languages: languages,
                    codeUrl: json.html_url
                } as UsableProjectDetails;
            });

        setRepoData(repoData);
    }

    useEffect(() => {
        GetRepo();
    }, []);

    return (
        <section className='project-page-section'>
            { !!titleImage
                ? <img className='project-page-title-image' src={titleImage} alt={`mainTitle-${id}`}  />
                : <h1 className='project-page-header'>{ friendlyRepoName }</h1>
            }
            
            <div className='project-page-inner-section'>
                { !!repoData?.liveDemoUrl
                        ?   <a className='project-page-image-link' href={repoData.liveDemoUrl} target='_blank' rel="noopener noreferrer">
                                <img className='project-page-main-image-in-link'
                                    src={mainPageImage}
                                    alt={`mainImage-${id}`} 
                                    onMouseEnter={
                                        () => {
                                            setCardHover(true)
                                        }
                                    } 
                                    onMouseLeave={() => {
                                        setCardHover(false)
                                    }} />
                            </a>
                        :   <img className='project-page-main-image'
                                src={mainPageImage}
                                alt={`mainImage-${id}`} 
                                onMouseEnter={
                                    () => {
                                        setCardHover(true)
                                    }
                                } 
                                onMouseLeave={() => {
                                    setCardHover(false)
                                }} />
                }
                <div className='project-page-main-summary'>
                    <h1>Description</h1>
                    <p>{repoData?.description}</p>
                    {/* <div className='project-page-button-section'>

                    </div> */}
                    {/* {!!repoData?.liveDemoUrl 
                        ?
                        <Link className='see-it-now' to={repoData.liveDemoUrl} target="_blank" rel="noopener noreferrer">
                            <div className='bubble-text'>
                                See it live!
                            </div>
                        </Link>
                        : <></>
                    } */}
                        
                    {!!repoData?.codeUrl
                        ? 
                            <Link className='see-code' to={repoData.codeUrl} target="_blank" rel="noopener noreferrer">
                                <div>How it was made</div>
                            </Link>
                        : <></>
                    }
                </div>
            </div>

            <div className='project-instructions'>
                <h3>Background</h3>
                <p>{ pageBackgroundDescription }</p>
            </div>

            {
                <ProjectInstructions id={id} />
            }

            { !!repoData?.liveDemoUrl 
                    ? <CustomCursor 
                        elementHover={cardHover}
                        hoverText='See It Live!'
                        link={repoData.liveDemoUrl} />
                    : <></> 
            }
        </section>
    );
}

const ProjectInstructions = (props: any) => {
    const projectInstructions = GetProjectInstructions(props.id ?? "");
    const hasSimpleInstructions = !!projectInstructions && !!projectInstructions.simpleInstructions;
    const hasNumberedInstructions = !!projectInstructions 
                                && !!projectInstructions.numberedInstructions 
                                && projectInstructions.numberedInstructions.length > 0;

    if (!projectInstructions)
        return <></>

    if (hasSimpleInstructions)
        return  <div className='project-instructions'>
                    <h3>How it works</h3>
                    <p>{projectInstructions.simpleInstructions}</p>
                </div>
    
    if (hasNumberedInstructions)
        return  <div className='project-instructions'>
                    <h3>How it works</h3>
                    {
                        projectInstructions.numberedInstructions?.map((x, index) => <p>{index + 1}. {x}</p>)
                    }
                </div>

    return <></>
}