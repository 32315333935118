import { GetMainTitles } from '../TitleAnimations/AnimatedTitleMap';
import './ExperiencePage.scss';

export const ExperiencePage = () => {
    const titleImage = GetMainTitles("experience");

    return <>
        {/* <h1 className='section-header'>Experience</h1> */}
        <img className='project-page-title-image' src={titleImage} alt={`mainTitle-experience`}  />

        <iframe 
            src="https://drive.google.com/file/d/1DUhZv2LirDACBK62IvDxtCSVCwslEUC5/preview" 
            width="100%" 
            height="650"
            title='Resume from Google Drive' />

    </>;
}