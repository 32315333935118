import Hangman from './hangman_chalk.png';
import Projects from './projects.png';
import Experience from './experience.png';

const otherTitleMap = new Map<string, any> ([
    [ "projects", Projects ],
    [ "experience", Experience ]
]);

const titleMap = new Map<string, any> ([
    [ "angular-hangman-dakodaw", Hangman ],
    [ "Appointment-Scheduler", null ],
    [ "avoid-using-function-calls-template", null ],
    [ "Budget", null ],
    [ "BudgetApi", null ],
    [ "Inventory", null ],
    [ "PartsInProducts", null ],
    [ "PortfolioAngular", null ],
    [ "react-calculator", null ],
    [ "rxjs-operators-template-pairwise", null ],
    [ "WGUMobileProject", null ],
    [ "", null ]
]);

export const GetMainRepoTitle = (repoName: string) => {
    return titleMap.get(repoName);
}

export const GetMainTitles = (titleName: string) => {
    return otherTitleMap.get(titleName);
}