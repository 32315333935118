import { GetProfileData } from '../Shared/Requests/GetProfileData';
import { GetSessionObject } from '../Shared/StorageService/StorageService';
import './AboutMePage.scss';
import { useEffect, useState } from "react";

export const AboutMe = () => {
    const profileKey: string = 'profileData';
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        const dataFromSession = GetSessionObject(profileKey);

        !!dataFromSession 
            ? setProfileData(dataFromSession as any)
            : GetProfileData(setProfileData);
      }, []);
      
    return (
        <section className='about-me-page-section'>
            <div className='framing family-frame'>
                <img src={!!profileData ? profileData['avatar_url'] : undefined} alt="a family consisting of a father, mother, daughter and baby son" />
            </div>
            <div className='about-me-text'>
                <p>I am Dakoda. I am a husband, a father, and a son. I love to have fun and be active. </p> 
                <p>I enjoy creating, solving problems, and learning new things. </p>
                <p>{!!profileData ? profileData['bio'] : null}</p>
            </div>
        </section>
    );
}