import { BrowserRouter, Routes as ReactRoutes, Route, useRoutes } from "react-router-dom";
import { PageContainer } from "./PageContainer";
import { Home } from "./Home/Home";
import { AboutMe } from "./AboutMePage/AboutMePage";
import { ProjectPage } from "./ProjectPage/ProjectPage";
import { ExperiencePage } from "./ExperiencePage/ExperiencePage";

export const Routes = (props: any) => {
    // return (
    //     <BrowserRouter basename={props.root}>
    //         <PageContainer>
    //             <ReactRoutes>
    //                 <Route path="/" Component={<Home/>}>
    //                 </Route>
    //             </ReactRoutes>
    //         </PageContainer>
    //     </BrowserRouter>
    // );
    let routes = useRoutes([
        { path: "/", element: <Home /> },
        { path: "about-me", element: <AboutMe /> },
        { path: "project/:id", element: <ProjectPage /> },
        { path: "experience", element: <ExperiencePage /> },
        { path: "*", element: <div>Not Found</div>}
        // ...
      ]);
      return routes;
    
}