import Hangman from './Hangman.mp4';
import PortfolioV1 from './PortfolioV1.mp4';
import SimpleReactCalculator from './SimpleReactCalculator.mp4';
import AvoidingDuplicateCalls from './AvoidingDuplicateCalls.mp4';
import RxJsPairwise from './RxJsPairwise.mp4';
import Budget from './Budget.mp4';
import Inventory from './Inventory.mp4';
import PartsInProduct from './PartsInProduct.mp4';
import WGUApp from './WGUApp.mp4';
import BudgetApi from './BudgetApi.mp4';

const videoMap = new Map<string, any> ([
    [ "angular-hangman-dakodaw", Hangman],
    [ "Appointment-Scheduler", null ],
    [ "avoid-using-function-calls-template", AvoidingDuplicateCalls ],
    [ "Budget", Budget ],
    [ "BudgetApi", BudgetApi ],
    [ "Inventory", Inventory ],
    [ "PartsInProducts", PartsInProduct ],
    [ "PortfolioAngular", PortfolioV1 ],
    [ "react-calculator", SimpleReactCalculator ],
    [ "rxjs-operators-template-pairwise", RxJsPairwise ],
    [ "WGUMobileProject", WGUApp ],
    [ "", null ]
])

export const GetRepositoryVideo = (name: string) => {
    return videoMap.get(name);
}