import { InstructionDetails } from "./InstructionDetails";

const BackgroundMap = new Map<string, string> ([
    [ "angular-hangman-dakodaw", 
        `There was a challenge with some people I worked with in 2019 to create a game using Angular, 
            and to give it a theme that you stuck to with css. 
            The game I chose was Hangman, and the theme was the old monochrome computers.` ],
    [ "Appointment-Scheduler", "This desktop app was a project for school." ],
    [ "avoid-using-function-calls-template", 
        `At work I was asked to present to other team members a way in rxjs to make something in Angular more efficient. 
        Something I had seen frequently was calling a method that calculates something that only needs to be calculated once. 
        If an action occurred in the parent component, and a button click happens, it gets calculated multiple times.
        Calculating it on init instead avoids unnecessary processing.` ],
    [ "Budget", 
        `I wanted a place where I could put in my purchases and keep track of my budget and where I was in comparison to it.
        I was using a spreadsheet, but it got slow very fast, and was a decent amount of work to update graphs 
        and sort things each time I started a new month. I started this budget app to help my wife and I to do this easier, 
        and be able to query it easier. I created it initially in .NetFramework, and used jQuery, JavaScript, HTML, and CSS.` ],
    [ "BudgetApi", 
        `I wanted a place where I could put in my purchases and keep track of my budget and where I was in comparison to it.
        I was using a spreadsheet, but it got slow very fast, and was a decent amount of work to update graphs 
        and sort things each time I started a new month. I started this budget app to help my wife and I to do this easier, 
        and be able to query it easier. I created it initially in .NetFramework, and used jQuery, JavaScript, HTML, and CSS.
        This project is me breaking out the Api into a separate solution, and upgrading it to use .Net Core. 
        I also use this to practice different things that I learn, and implement better practices.` ],
    [ "Inventory", 
        `This project was based on a problem at work I was trying to solve. I was in charge of keeping track of inventory for 
        computer equipment such as desktops, laptops, monitors, printers, etc. It was very large and slow, and keeping track of 
        the history of who owned what when was very limited. I had taken some programming classes in school in the past and decided
        to give it a shot to create a project that I could bring to work to help myself keep track of this equipment, its history,
        and give easier visibility to others on the team.` ],
    [ "PartsInProducts", 
        `This was a project for school that is a Windows Desktop App that you can create 'parts', and create 
        'products' that put different 'parts' together.` ],
    [ "PortfolioAngular", 
        `This is my old Portfolio website. I created it when I was first learning Angular to help get into a job.
        I have spent time from tiem to time to keep things more upgraded, and update resumes and things.` ],
    [ "react-calculator", "This was a project that I created to start learning and practicing React, and to present in an interview." ],
    [ "rxjs-operators-template-pairwise", "I created this project to present on an rxjs operator that wasn't as commonly used in a work meeting." ],
    [ "WGUMobileProject", "This Mobile app was created with Xamarin for a school project." ],
    [ "", "" ]
]);

const InstructionsMap = new Map<string, InstructionDetails> ([
    [ "angular-hangman-dakodaw", 
        {
            // simpleInstructions: "Keep trying"
            numberedInstructions: [
                "Press any key to get started",
                "Try guessing a letter at a time.",
                "Each letter that does not belong in the chosen phrase related to technology will draw a peice of hangman on the screen",
                "If the whole man is drawn, you'll be brought to a screen saying you lost.",
                "If the word is guessed before then, you win",
                "You may try again as many times as you'd like"
            ]
        } 
    ],
    [ "Appointment-Scheduler",
        {
            simpleInstructions: ""
        } 
    ],
    [ "avoid-using-function-calls-template",
        {
            simpleInstructions: ""
        } 
    ],
    [ "Budget",
        {
            simpleInstructions: ""
        } 
    ],
    [ "BudgetApi",
        {
            simpleInstructions: ""
        } 
    ],
    [ "Inventory",
        {
            simpleInstructions: ""
        } 
    ],
    [ "PartsInProducts",
        {
            simpleInstructions: ""
        } 
    ],
    [ "PortfolioAngular",
        {
            simpleInstructions: ""
        } 
    ],
    [ "react-calculator",
        {
            simpleInstructions: ""
        } 
    ],
    [ "rxjs-operators-template-pairwise",
        {
            simpleInstructions: ""
        } 
    ],
    [ "WGUMobileProject",
        {
            simpleInstructions: ""
        } 
    ],
    [ "", {} ]
])

export const GetProjectBackground = (name: string): string => {
    return BackgroundMap.get(name) ?? "";
}

export const GetProjectInstructions = (name: string): InstructionDetails => {
    return InstructionsMap.get(name) ?? {};
}