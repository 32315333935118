const friendlyNameMap = new Map<string, string> ([
    [ "angular-hangman-dakodaw", "Hangman" ],
    [ "Appointment-Scheduler", "Appointment Scheduler" ],
    [ "avoid-using-function-calls-template", "Avoid Using Function Calls Template" ],
    [ "Budget", "Budget" ],
    [ "BudgetApi", "Budget Api" ],
    [ "Inventory", "Inventory" ],
    [ "PartsInProducts", "Parts in a Product" ],
    [ "PortfolioAngular", "Portfolio V1" ],
    [ "react-calculator", "Calculator" ],
    [ "rxjs-operators-template-pairwise", "Rxjs Pairwise" ],
    [ "WGUMobileProject", "Test School App" ],
    [ "", "" ]
]);

export const GetFriendlyRepoName = (repoName: string) => {
    return friendlyNameMap.get(repoName);
}