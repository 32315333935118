import './ProjectSection.scss';
import { ProjectPreviewInput } from "../ProjectPreview/ProjectPreviewInput";
import { Parallax } from 'react-scroll-parallax';
import { GetRepositoryVideo } from '../Videos/VideoMap';
import { GetFriendlyRepoName } from '../Shared/GetFriendlyRepoName';
import { Link } from 'react-router-dom';
import { TechIcon } from '../Shared/Components/TechIcon/TechIcon';

export const ProjectSection = (props: ProjectPreviewInput) => {
    const video = GetRepositoryVideo(props.projectInfo.name);
    const friendlyRepoName = GetFriendlyRepoName(props.projectInfo.name);
    
    return (
        <section className='project-section'>
            <Link to={"project/" + props.projectInfo.name}
                onMouseEnter={
                    () => {
                        props.setCardHover(true)
                    }
                } 
                onMouseLeave={() => {
                    props.setCardHover(false)
                }} >
                {!video
                    ?   <div className='framing'>
                            <div className='section-icon'>
                                <h4>{friendlyRepoName}</h4>
                            </div>
                        </div> 
                    :   <div className='video-container framing'>
                            <video className='section-video' loop autoPlay={true} muted playsInline >
                                <source src={video} type="video/mp4"/>
                            </video> 
                        </div>
                }
            </Link>
            <Parallax speed={2} className='section-description'>
                { props.id % 2 === 1
                    ?   <div className="talk-bubble round right-in">
                            <div className="talktext">
                            <h3>{friendlyRepoName}</h3>
                            <p>Technologies:</p>
                                {props.projectInfo.languages.map(language => (
                                    <TechIcon key={`${props.projectInfo.name}-language-${language}`} techName={language} />
                                ))}
                            </div>
                        </div>
                    :   <div className="talk-bubble round left-in">
                            <div className="talktext">
                            <h3>{friendlyRepoName}</h3>
                            <p>Technologies:</p>
                                {props.projectInfo.languages.map(language => (
                                    <TechIcon key={`${props.projectInfo.name}-language-${language}`} techName={language} />
                                ))}
                            </div>
                        </div>
                }
            </Parallax>
        </section>
    );
}