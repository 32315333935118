import './TypeWriterText.scss'
import ReactTyped from "react-typed";

export interface TypeWriterTextInput {
    values: string[];
}

export const TypeWriterText = (props: TypeWriterTextInput) => {
    return (
      <ReactTyped className='typewriter' strings={props.values} typeSpeed={120} loop />
    );
}