import './Footer.scss';
import GitHubIcon from './GithubIcon.svg';
import LinkedInIcon from './LinkedInIcon.svg';

export const Footer = () => (
    <section className='footer-container'>
        <h3 className='current-year'>
            Dakoda Willden {new Date().getFullYear()}
        </h3>
        <div className='socials'>
            <a href='https://github.com/dakodaw'>
                <img src={GitHubIcon} className='social-media-icon' title='GitHub' alt='GitHub Icon' />
            </a>
            <a href="https://www.linkedin.com/in/dakoda-willden-2b2850b5/">
                <img src={LinkedInIcon} className='social-media-icon' title='LinkedIn' alt='LinkedIn Icon' />
            </a>
        </div>
    </section>
);