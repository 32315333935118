import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";

export const PageContainer = (props: React.PropsWithChildren) => {
    return ( 
        <>
        <Header />
        {props.children}
        <Footer/>
        </>
    );
}