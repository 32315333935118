import { useState, useEffect } from 'react';
import './CustomCursor.scss'
import { Link } from 'react-router-dom';

export interface MouseDivInput {
    elementHover: any,
    hoverText: string,
    link?: string
}

export function CustomCursor(input: MouseDivInput) {

    const [posX, setPosX] = useState((window as any).pageX);
    const [posY, setPosY] = useState((window as any).pageY);

    const locateMouse = (e: any) => {
        setPosX(e.pageX);
        setPosY(e.pageY);
    }

    useEffect(() => {
        setPosY(window.scrollY);
        window.addEventListener('mousemove', locateMouse);

        return () => window.removeEventListener('mousemove', locateMouse);
    }, [])

  const mouseStyle = {
    top: `calc(${posY}px - 2vh`,
    left: `calc(${posX}px - calc(2rem + 1vw))`,
    opacity: input.elementHover ? 1 : 0,
    transform: input.elementHover ? 'scale(1)' : 'scale(0)',
  }

  return (
    <ScMouseDiv style={mouseStyle} link={input.link}>
        <p>{input.hoverText}</p>
    </ScMouseDiv>
  )
}

const ScMouseDiv = (props: any) => {
    return (
        <div style={props.style} className='custom-cursor'>
            {props.children}
        </div>    
    )
}

export default CustomCursor