import { SetSessionObject } from "../StorageService/StorageService";

export const GetProfileData = async (setProfileDataFn: any) => {
    const profileKey: string = 'profileData';

    const profileData = await fetch(`https://api.github.com/users/dakodaw`)
        .then(response => response.json());
    
    SetSessionObject(profileKey, profileData);
    setProfileDataFn(profileData);
}