import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './Routes';
import { PageContainer } from './PageContainer';
import { ParallaxProvider } from 'react-scroll-parallax';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <PageContainer>
          <ParallaxProvider>
            <Routes />
          </ParallaxProvider>
        </PageContainer>
      </BrowserRouter>
    </div>
  );
}

export default App;
