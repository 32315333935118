import { Link } from "react-router-dom";
import './Header.scss'
import { TypeWriterText } from "../TypeWriterText/TypeWriterText";

export const Header = () => {
  const roles = [
    "Husband",
    "Father",
    "Software Engineer"
  ]
    return (
      <div className="navbar-container">
          <div>
            <h2 className='navbar-name'>
                Dakoda Willden
            </h2>
            <TypeWriterText values={roles}/>
          </div>
          <div className='main-page-swap'>
            <Link className='clickable' to={"/"}>
                Projects
            </Link> 
            <div> | </div>
            <Link className='clickable' to={"/experience"}>
                Experience
            </Link> 
            <div> | </div>
            <Link className='clickable' to="/about-me">
                About Me
            </Link>
          </div>
      </div >
    );
  }